// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head{
    font-weight: 40px;
    font-size: 32px;
}

.event-name{
    margin-right: 10px;
}

h2.event-name-head,h2.designs-head{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 12px;
}

.event-name .item{
    margin-bottom: 25px;
}

.event-name .item .img{
    margin-left: 15px;
    position: relative;
}

.event-name .item .img:before {
    content: "";
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
    bottom: -10px;
    width: 2px;
    height: 60%;
    background-color: #e5dbde;
}

.event-name .item:nth-last-child(1) .img:before {
    display: none;
}
.item .details p{
    color: #171212;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.item .details span{
    color: #876370;
}

.add-event{
    text-align: end;
}

.add-event span{
    background-color: #F5F0F2;
    color: #171212;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 16px;
    border-radius: 10px;
    cursor: pointer;
}

.designs-images img{
    border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/home.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,4BAA4B;IAC5B,aAAa;IACb,UAAU;IACV,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".head{\n    font-weight: 40px;\n    font-size: 32px;\n}\n\n.event-name{\n    margin-right: 10px;\n}\n\nh2.event-name-head,h2.designs-head{\n    font-size: 22px;\n    font-weight: 700;\n    margin-bottom: 12px;\n}\n\n.event-name .item{\n    margin-bottom: 25px;\n}\n\n.event-name .item .img{\n    margin-left: 15px;\n    position: relative;\n}\n\n.event-name .item .img:before {\n    content: \"\";\n    position: absolute;\n    right: 50%;\n    transform: translate(50%, 0);\n    bottom: -10px;\n    width: 2px;\n    height: 60%;\n    background-color: #e5dbde;\n}\n\n.event-name .item:nth-last-child(1) .img:before {\n    display: none;\n}\n.item .details p{\n    color: #171212;\n    font-size: 16px;\n    font-weight: 500;\n    margin-bottom: 0;\n}\n\n.item .details span{\n    color: #876370;\n}\n\n.add-event{\n    text-align: end;\n}\n\n.add-event span{\n    background-color: #F5F0F2;\n    color: #171212;\n    font-size: 14px;\n    font-weight: 700;\n    padding: 10px 16px;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n.designs-images img{\n    border-radius: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
