import { Outlet, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import "./layout.css"
function Layout() {
  if(!sessionStorage.getItem('token')){
    window.location.replace("./LandingPage")
  }
  const [url,setUrl]= useState(sessionStorage.getItem('url'));
  const [token,setToken]= useState(sessionStorage.getItem('token'));
  const [image,setImage]=useState("");
  useEffect(() => {
    axios.get(url+"/user",{
      headers: {
        'Accept': 'application/json',
        "ngrok-skip-browser-warning": "69420",
        "Authorization":`Bearer ${token}`
      }
    }).then((response) => {
        setImage(response.data.user.image)
        return 
    }).catch(function (error) {
      if(error.response.status===401){
          sessionStorage.removeItem("token");
          window.location.replace("./login")
        
      }
  });
}, []);
  const {pathname} = useLocation();
  return (

    <>
    <Navbar collapseOnSelect expand="lg" className="bg-white navbar container">
      <Container className="justify-content-start">
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                <Navbar.Brand href="">
                    <DropdownButton id="dropdown-basic-button" title={
                        <img className="img_user" src={image}/>
                    }>
                        {/* <Dropdown.Item href="/edit">تعديل</Dropdown.Item> */}
                        <Dropdown.Item href="/logout">تسجيل الخروج</Dropdown.Item>
                    </DropdownButton>
                </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="text-end">
                <Nav className="me-auto">
                  <Link className={(pathname === '/home') ? 'active' : ''} to="/home">الرئيسية</Link>
                  <Link className={(pathname === '/event') ? 'active' : ''} to="event">حفلاتي</Link>
                  <Link className={(pathname === '/createevent') ? 'active fixed' : 'fixed'} to="createevent">إنشاء حفل</Link>
                </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      <Outlet />
      </>
      
  );
}

export default Layout;
  