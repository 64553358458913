import img from "../../images/event-item.png"
import { Link } from "react-router-dom";
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import "./home.css"
function Home() {
  if(!sessionStorage.getItem('token')){
    window.location.replace("./LandingPage")
  }

    const [url,setUrl]= useState(sessionStorage.getItem('url'));
    const [profile,setProfile]=useState({"latest_events":[],"latest_designs":[],"your_events":[]})
    const [token,setToken]= useState(sessionStorage.getItem('token'));
    const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
    const [loader, setLoader]=useState(true);
    useEffect(() => {
        axios.get(url+"/profile",{
          headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420",
            "Authorization":`Bearer ${token}`
          }
        }).then((response) => {
          setLoader(false)
            setProfile(response.data.data)
            return 
        }).catch(function (error) {});
          
    }, []);
    
    return (
      <div className="home">
        {
          loader?
            <div className="loader-container">
              <div className="loader"></div>
            </div>:""
        }
        <title>{app_name} - الرئيسية</title>
        <div className="container">
            <h1 className="head mt-4 mb-4">مرحبا بك من جديد، محمد</h1>
            <div className="event-name">
                <h2 className="event-name-head">آخر الحفلات</h2>
                <div className="event-item">
                    {profile.latest_events.map((item)=>
                        <div key={item.id} className="item d-flex">
                            <div className="img">
                              <img src={img}/>
                            </div>
                            <div className="details">
                              <p>{item.name}</p>
                              <span>{item.invites_count}</span>
                            </div>
                        </div>
                    
                    )}
                </div>
            </div>
            <div className="designs">
              <h2 className="designs-head">أحدث التصميمات</h2>
              <div className="designs-images row">
                  {profile.latest_designs.map((item)=>
                    <div key={item.id} className="px-4 col-lg-3 col-md-4 col-6 mb-4">
                      <img src={item.image}/>
                    </div>
                  )}
                  
              </div>
            </div>
            <div className="add-event">
              <Link  to="../createevent"><span>أضف حفلا جديدا</span></Link>
              {/* <span>أضف حفلا جديدا</span> */}
            </div>
            <div className="event-name">
                <h2 className="event-name-head">حفلاتك</h2>
                <div className="event-item">
                    {profile.your_events.map((item)=>
                            <div key={item.id} className="item d-flex">
                                <div className="img">
                                  <img src={img}/>
                                </div>
                                <div className="details">
                                  <p>{item.name}</p>
                                  <span>{item.invites_count}</span>
                                </div>
                            </div>
                        
                        )}
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Home;
  