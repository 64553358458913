import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/home";
import Login from "./components/login/login";
import Layout from "./components/layout/layout";
import CreateEvent from "./components/createevent/createevent";
import Event from "./components/event/event";
import LandingPage from "./components/LandingPage/LandingPage";
import "./App.css";
import Logout from "./components/logout/logout";
import ScanQr from "./components/ScanQr/ScanQr"
function App() {
  sessionStorage.setItem('url',"https://api.wanasatouch.com/api")
  const [settings, setSettings]=useState([]);
  const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
  const [loader, setLoader]=useState(true);

    useEffect(() => {
      axios.get("https://api.wanasatouch.com/api/settings",{
        headers: {
          'Accept': 'application/json',
          "ngrok-skip-browser-warning": "69420",
        }

      }).then((response) => {

          setLoader(false)
          console.log(loader)
          sessionStorage.setItem('app_name',response.data.data.app_name)
          setAppName(response.data.data.app_name);
          setSettings(response.data.data)
          return 
      }).catch(function (error) {});;
  }, []);
  var linkElement = document.querySelector("link[rel='icon']");
      linkElement.href = settings.app_logo;
  return (
    <div className="App">
      {
        loader?
          <div className="loader-container">
            <div className="loader"></div>
          </div>:""
      }
      
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
            {/* <Route index element={<Home />} /> */}
              <Route path="home" element={<Home />} />
              <Route path="createevent" element={<CreateEvent />} />
              <Route path="event" element={<Event />} />
              <Route path="scanqr" element={<ScanQr />} />
            </Route>
            <Route path="/LandingPage" element={<LandingPage />}>              
            </Route>
            <Route path="/login" element={<Login />}>              
            </Route>
            <Route path="/logout" element={<Logout />}>              
            </Route>
            
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
