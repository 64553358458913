
import star from "../../images/star.png"
import star2 from "../../images/star2.png"
import { Link } from "react-router-dom";
import React, { useState,useEffect } from 'react';
import axios from 'axios';

import "./LandingPage.css"
function LandingPage(){
    const [url,setUrl]= useState(sessionStorage.getItem('url'));
    const [images,setImages]=useState([])
    const [features,setFeatures]=useState([])
    const [home,setHome]=useState([])
    const [reviews,setReviews]=useState([])
    const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
    const [loader, setLoader]=useState(true);
    useEffect(() => {
        axios.get(url+"/home-data",{
          headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420"
          }
        }).then((response) => {
            setLoader(false)
            setHome(response.data.data)
            console.log(response.data.data)
            return 
        }).catch(function (error) {});;
    }, []);
    useEffect(() => {
        axios.get(url+"/home-images",{
          headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420"
          }
        }).then((response) => {
            setImages(response.data.home_images)
            return 
        }).catch(function (error) {});;
    }, []);
    useEffect(() => {
        axios.get(url+"/features",{
          headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420"
          }
        }).then((response) => {
            setFeatures(response.data.features)
            return 
        }).catch(function (error) {});;
    }, []);
    useEffect(() => {
        axios.get(url+"/reviews",{
        headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420"
        }
        }).then((response) => {
            setReviews(response.data.reviews)
            return 
        }).catch(function (error) {});;
    }, []);

    return(
        <div className="LandingPage mt-4">
            <title>{app_name} - الرئيسية</title>
            {
          loader?
            <div className="loader-container">
              <div className="loader"></div>
            </div>:""
        }
            <div className="container">
                <div className="main" style={{backgroundImage: "url(" + home.home_background + ")"}}>
                    <div className="overview"></div>
                    <h1>{home.home_title}</h1>
                    <p className="mb-4">{home.home_subtitle}</p>
                </div>
                    <div className="choose mb-3 ">
                        <Link className='choose-link mb-5' to="../createevent">ابدأ إرسال الدعوات</Link>
                    </div>
                <div className="img-design row">
                    {images.map((image)=>
                        <div key={image.id} className="col-6 col-lg-3 col-md-4 mb-3">
                            <img src={image.image}/>
                        </div>
                    )}
                </div>
                <h1 className="head mt-4 mb-2">لماذا تختار موقع {app_name}؟</h1>
                <p className="mb-4">{home.home_why_text}
                </p>
                <div className="features mb-5">
                    <div className="row">
                        {features.map((feature)=>
                            <div key={feature.id} className="col-md-4 col-sm-6 col-12">
                                <img className="mb-3" src={feature.image}/>
                                <h4 className="mb-0">{feature.title}</h4>
                                <p>{feature.subtitle}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="opinions">
                    {reviews.map((reviews)=>
                        <div key={reviews.id} className="card-opinion mb-4">
                            <div className="d-flex">
                                <img src={reviews.image}/>
                                <div className="det">
                                    <h6>{reviews.name}</h6>
                                    <span>{reviews.created_at}</span>
                                </div>
                            </div>
                            <div className="icons mb-2 mt-2">
                                {Array.from({length: reviews.rating}).map((b)=>
                                    <img key={Math.random()} src={star}/>
                                )}
                                {Array.from({length: 5-reviews.rating}).map((b)=>
                                    <img key={Math.random()} src={star2}/>
                                )}
                            </div>
                                <p>{reviews.review}</p>
                        </div>
                    )}
                    
                </div>
                <h1 className="head text-center mt-5 mb-5">على استعداد لإنشاء دعوات الزفاف الخاصة بك؟</h1>
                <div className="start mb-5 text-center">
                    <Link className='fixed' to="../createevent">البدء</Link>
                </div>
            </div>
        </div>
    )
}
export default LandingPage;