// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  direction: rtl;
  font-family: "Rubik", sans-serif;
  color: #171212;
}
a{
  text-decoration: none;
}

img{
  width: 100%;
}

form{
  color: #e91e63;
  font-size: 12px;
}

/* HTML: <div class="loader"></div> */
.loader-container{
  position: fixed;
  display: flex;
  top: 0%;
  right: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}
.loader {
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #f03355 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gCAAgC;EAChC,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA,qCAAqC;AACrC;EACE,eAAe;EACf,aAAa;EACb,OAAO;EACP,QAAQ;EACR,cAAc;EACd,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,wBAAwB;EACxB,iCAAiC;AACnC;AACA;;EAEE,WAAW;EACX,cAAc;EACd,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,uBAAuB;EACvB,4BAA4B;AAC9B;AACA;EACE,WAAW;AACb;AACA;EACE,KAAK,wBAAwB;AAC/B","sourcesContent":["body{\n  direction: rtl;\n  font-family: \"Rubik\", sans-serif;\n  color: #171212;\n}\na{\n  text-decoration: none;\n}\n\nimg{\n  width: 100%;\n}\n\nform{\n  color: #e91e63;\n  font-size: 12px;\n}\n\n/* HTML: <div class=\"loader\"></div> */\n.loader-container{\n  position: fixed;\n  display: flex;\n  top: 0%;\n  right: 0;\n  z-index: 99999;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  background-color: #f5f5f5;\n}\n.loader {\n  width: 50px;\n  height: 50px;\n  aspect-ratio: 1;\n  display: grid;\n  border: 4px solid #0000;\n  border-radius: 50%;\n  border-color: #ccc #0000;\n  animation: l16 1s infinite linear;\n}\n.loader::before,\n.loader::after {    \n  content: \"\";\n  grid-area: 1/1;\n  margin: 2px;\n  border: inherit;\n  border-radius: 50%;\n}\n.loader::before {\n  border-color: #f03355 #0000;\n  animation: inherit; \n  animation-duration: .5s;\n  animation-direction: reverse;\n}\n.loader::after {\n  margin: 8px;\n}\n@keyframes l16 { \n  100%{transform: rotate(1turn)}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
