import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatepickerField = ({
  field, 
  form, 
  ...props
}) => (
  // OR const { setFieldValue } = form;
  // OR const { value, name } = field;
  <div>
    {/* {console.log({...props})} */}
    <Datepicker
      placeholderText="التاريخ والوقت"
      showTimeSelect
      dateFormat="dd-MM-yyyy H:m:s"
      {...field}
      selected={field.value}
      onChange={(val) => form.setFieldValue(field.name, val)}
      
    />
  </div>
);
export default DatepickerField;