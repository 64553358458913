import { Scanner } from '@yudiel/react-qr-scanner';
import React, { useState } from 'react';
import "./qr.css"
import { useLocation } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


function ScanQr(params) {
    const [url,setUrl]= useState(sessionStorage.getItem('url'));
    const [token,setToken]= useState(sessionStorage.getItem('token'));
    const [st,setSt]=useState(false);
    const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
    const query = useQuery()
    const id = query.get('event_id')
    const scannerStyles = {
        container: {
            width: '100%',
            maxHeight:"600px"
        },
        video: {
            width: '100%',
            maxHeight:"600px",
            height: "100%"
        },
        finderBorder: 100
    }
    const scanner = (result)=>{
        setSt(true)
        console.log(result[0].rawValue)
        axios.post(url+"/events/"+id+"/attend",{"qr_code":result[0].rawValue}, {
            headers: {
              'Accept': 'application/json',
              "ngrok-skip-browser-warning": "69420",
              "Authorization":`Bearer ${token}`
            }
          }).then((response) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500
            });
          }).catch(function (error) {
            console.log(error)
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: error.response.data.message,
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
        });
      
    }
    return(
        <div className='container mt-5 qr'>
          <title>{app_name} - الحفلات</title>
            <button className='mb-5 btn btn-danger' onClick={()=>{ setSt(false)}}>امسح كود آخر</button>
            <Scanner classNames="mb-5" styles={scannerStyles} paused={st} onScan={(result)=> scanner(result)}/>
        </div>
    )
}

export default ScanQr