import { useState,useEffect } from 'react';
import axios from 'axios';

function Logout(params) {
    const [url,setUrl]= useState(sessionStorage.getItem('url'));
    const [token,setToken]= useState(sessionStorage.getItem('token'));
    
    useEffect(() => {
        axios.get(url+"/logout",{
            headers: {
                'Accept': 'application/json',
                "ngrok-skip-browser-warning": "69420",
                "Authorization":`Bearer ${token}`
            }
        }).then((response) => {
            sessionStorage.removeItem("token");
            window.location.replace("./login")
            return 
        }).catch(function (error) {});;
    }, []);
}
export default Logout