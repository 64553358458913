import { Link } from "react-router-dom";
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'

import "./event.css"

function Event() {
  if(!sessionStorage.getItem('token')){
    window.location.replace("./LandingPage")
  }
  const [url,setUrl]= useState(sessionStorage.getItem('url'));
  const [token,setToken]= useState(sessionStorage.getItem('token'));
  const [events,setEvents]=useState([]);
  const [id,setId]=useState();
  const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
  const [loader, setLoader]=useState(true);
  useEffect(() => {
    axios.get(url+"/events",{
      headers: {
        'Accept': 'application/json',
        "ngrok-skip-browser-warning": "69420",
        "Authorization":`Bearer ${token}`
      }
    }).then((response) => {
      setLoader(false)
        setEvents(response.data.events)
        return 
    }).catch(function (error) {});
}, []);
const end = (id)=>{
  setId(id)
  Swal.fire({
    title: "هل أنت متأكد؟",
    text: "لن تتمكن من الرجوع عن هذا الإجراء!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "تأكيد",
    cancelButtonText: "إلغاء"
  }).then((result) => {
    if (result.isConfirmed) {
      axios.patch(url+"/events/"+id+"/end",{}, {
        headers: {
          'Accept': 'application/json',
          "ngrok-skip-browser-warning": "69420",
          "Authorization":`Bearer ${token}`
        }
      }).then((response) => {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        });
          axios.get(url+"/events",{
            headers: {
              'Accept': 'application/json',
              "ngrok-skip-browser-warning": "69420",
              "Authorization":`Bearer ${token}`
            }
          }).then((response) => {
              setEvents(response.data.events)
              return 
          }).catch(function (error) {});;
          return 
      }).catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
    });
    }
  });
    
}
const start = (id)=>{
  setId(id)
  Swal.fire({
    title: "هل أنت متأكد؟",
    text: "لن تتمكن من الرجوع عن هذا الإجراء!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "تأكيد",
    cancelButtonText: "إلغاء"
  }).then((result) => {
    if (result.isConfirmed) {
      
      axios.patch(url+"/events/"+id+"/start",{}, {
        headers: {
          'Accept': 'application/json',
          "ngrok-skip-browser-warning": "69420",
          "Authorization":`Bearer ${token}`
        }
      }).then((response) => {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        });
          axios.get(url+"/events",{
            headers: {
              'Accept': 'application/json',
              "ngrok-skip-browser-warning": "69420",
              "Authorization":`Bearer ${token}`
            }
          }).then((response) => {
              setEvents(response.data.events)
              return 
          }).catch(function (error) {});
          return 
      }).catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
    });
    }
  });
}
    return (
      
      <div className="event mt-4">
        <title>{app_name} - الحفلات</title>
        {
          loader?
            <div className="loader-container">
              <div className="loader"></div>
            </div>:""
        }
        <div className="container">
            <div className="add-event">
                <Link  to="../createevent"><span>حفل جديد</span></Link>
            </div>
            <h2 className="event-name-head">حفلات الزفاف</h2>
            <div className="tableapp">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">الاسم</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">عدد المدعوين</th>
                    <th scope="col">الحالة</th>
                    <th scope="col">الإجراءات</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event)=>
                    <tr key={event.id}>
                      <td>{event.name}</td>
                      <td>{event.time}</td>
                      <td>{event.invites_count}</td>
                      <td>{event.status}</td>
                      <td>
                        {
                          event.status_key==="not_started"? <button onClick={()=> start(event.id)} className="m-2 btn btn-success">بدأ الحفل</button>
                          :event.status_key==="in_progress"?
                          <><button onClick={()=>end(event.id)} className="btn btn-danger m-2">إنهاء الحفل</button> 
                          <Link className="btn btn-primary" to={"/scanqr?event_id="+event.id}>تسجيل الحضور</Link></>
                          :""
                        }
                        </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
        </div>
      </div>
    );
  }
  
  export default Event;
  